import Button from '@mui/material/Button'

import facebookCheckPagesImg from 'assets/Images/facebook-check-pages.jpg'
import { FacebookAuthDialogProps } from 'components/Dialog/Dialog.types'

import { Content, StyledDialog } from './FacebookAuth.styles'

const FacebookAuthDialog = ({
  open,
  onClose,
  onConfirm,
}: FacebookAuthDialogProps) => {
  const handleClose = () => {
    onClose()
  }

  const handleConfirm = () => {
    onConfirm()
  }

  const header = <img src={facebookCheckPagesImg} alt='Check all pages' />

  const content = (
    <Content>
      <header>
        <h2>Make sure to check all pages</h2>
      </header>
      <p>You will be redirected to Facebook.</p>
      <p>
        Select all of your Facebook accounts and Facebook pages to ensure all
        your integrations remain fully functional.
      </p>
    </Content>
  )

  const actions = (
    <>
      <Button variant='outlined' onClick={handleClose}>
        Cancel
      </Button>
      <Button variant='contained' onClick={handleConfirm}>
        Continue to Facebook
      </Button>
    </>
  )

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      header={header}
      content={content}
      actions={actions}
      data-testid='MessengerAuth_dialog'
    />
  )
}

export default FacebookAuthDialog
