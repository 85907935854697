import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding: 0.5rem 4rem;
  text-align: left;
  font-size: 0.875rem;
  background: #ffffff;
  min-height: 100vh;
  p {
    margin: 1rem 0;
  }
`

export const StyledText = styled.p`
  font-size: 0.875rem;
`
