import { ReactNode, createContext } from 'react'

import {
  IntegrationState,
  UseFacebookIntegrations,
} from 'hooks/useFacebookIntegrations.types'
import { useFbIntegrations } from 'hooks/useFacebookIntegrations'

export const IntegrationContext = createContext<UseFacebookIntegrations>({
  facebookIntegrationState: { current: [], available: {}, error: null },
  fetchCurrentFacebookIntegrations: () => new Promise(() => null),
  fetchAvailableFacebookIntegrations: () => new Promise(() => null),
  addFacebookIntegration: () => new Promise(() => null),
})

type ContextType = {
  state?: IntegrationState
  children: ReactNode
}

const defaultState = {
  current: [],
  available: {},
  error: null,
}

export const IntegrationContextProvider = ({
  state = defaultState,
  children,
}: ContextType) => {
  // use hook, pass through state and methods
  const {
    facebookIntegrationState,
    fetchCurrentFacebookIntegrations,
    fetchAvailableFacebookIntegrations,
    addFacebookIntegration,
  } = useFbIntegrations(state)
  return (
    <IntegrationContext.Provider
      value={{
        facebookIntegrationState,
        fetchCurrentFacebookIntegrations,
        fetchAvailableFacebookIntegrations,
        addFacebookIntegration,
      }}
    >
      {children}
    </IntegrationContext.Provider>
  )
}
