import styled from 'styled-components'
import Skeleton from '@mui/material/Skeleton'

export const Container = styled.div`
  padding: 1rem 3rem;
`

export const LoadingBox = styled.div`
  flex: 1;
  display: flex;
  width: 50%;
  margin-bottom: 2rem;
`

export const Avatar = styled(Skeleton)`
  display: block;
  position: relative;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem 0.5rem 0 0;
`

export const Bubble = styled(Skeleton)`
  flex: 1;
  max-width: 20rem;
  height: 4rem;
  border-radius: 20px;
  transform: scale(1, 1);
`
