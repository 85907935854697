import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client'

import { AuthOptions, createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'

export type Config = {
  url: string
  subscriptionUrl: string
  region: string
  auth: AuthOptions
}

const createClient = (config: Config) => {
  const { url, subscriptionUrl, region, auth } = config
  const httpLink = createHttpLink({ uri: url })

  const link = ApolloLink.from([
    createAuthLink({ url, region, auth }),
    createSubscriptionHandshakeLink(
      { url: subscriptionUrl, region, auth },
      httpLink
    ),
  ])

  return new ApolloClient({
    link,
    cache: new InMemoryCache(),
  })
}

export default createClient
