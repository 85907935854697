import styled from 'styled-components'

export const Container = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  background-color: #e5e5e5;
  font-size: 1rem;
  overflow: hidden;
`

export const Sidebar = styled.div`
  background-color: #ffffff;
  border-right: 1px #e7e7e9 solid;
  display: flex;
  flex-direction: column;
  width: 16rem;
`
