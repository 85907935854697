import Typography from '@mui/material/Typography'

import { StyledHeader } from './Header.styles'

const Header = ({ title }: { title: string }) => {
  return (
    <StyledHeader>
      <Typography variant='h5' component='div' sx={{ mr: 2 }}>
        {title}
      </Typography>
    </StyledHeader>
  )
}

export default Header
