import MuiListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import Avatar from '@mui/material/Avatar'
import { getInitials, toColor } from 'utils/StringUtils'
import { Title, TitleContainer, TitleInfo } from './ListItem.styles'
import { ListItemProps } from './ListItem.types'

export default function ListItem({
  title,
  titleInfo,
  avatarUrl,
  content,
  onClick,
}: ListItemProps) {
  const renderAvatar = () => {
    return (
      <Avatar
        test-dataid='avatar'
        src={avatarUrl}
        alt={`${title} avatar`}
        sx={{ bgcolor: toColor(title, 50, 50) }}
      >
        {getInitials(title, 2)}
      </Avatar>
    )
  }

  const renderTitle = () => {
    return (
      <TitleContainer test-dataid='title'>
        <Title>{title}</Title>
        <TitleInfo>{titleInfo}</TitleInfo>
      </TitleContainer>
    )
  }

  return (
    <MuiListItem disablePadding>
      <ListItemButton onClick={onClick}>
        <ListItemAvatar>{renderAvatar()}</ListItemAvatar>
        <ListItemText primary={renderTitle()} secondary={content} />
      </ListItemButton>
    </MuiListItem>
  )
}
