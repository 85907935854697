import {
  MessageAuthorContainer,
  MessageBubbleText,
} from './MessageBubble.styles'

import {
  MessageBubbleAlignment,
  MessageBubbleProps,
} from './MessageBubble.types'
import { MessageContentType } from 'hooks/useMessages.types'

const MessageBubble = ({
  avatarIcon,
  align,
  content,
  type,
  timestamp,
}: MessageBubbleProps) => {
  let displayedContent

  switch (type) {
    /* @TODO: Add more types when we support them */
    case MessageContentType.TEXT: {
      displayedContent = (
        <MessageBubbleText primary={content} secondary={timestamp} />
      )
    }
  }

  return align === MessageBubbleAlignment.LEFT ? (
    <>
      <MessageAuthorContainer>{avatarIcon}</MessageAuthorContainer>
      {displayedContent}
    </>
  ) : (
    /** @TODO: Create the right aligned message bubble or possibly just modify the top level div to support it */
    <></>
  )
}

export default MessageBubble
