import { useSessionStorage } from './useSessionStorage'
import jwt from 'jsonwebtoken'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export const TOKEN_KEY = 'TOKEN'

export interface AuthDetail {
  token: string
  clientId: string
  userId: string
}

export interface UseAuthPayload {
  auth: AuthDetail
  setAuth: Dispatch<SetStateAction<AuthDetail>>
  setAuthToken: Dispatch<SetStateAction<any>>
}

export const useAuth = (): UseAuthPayload => {
  const [auth, setAuth] = useState<AuthDetail>({} as any)
  const [token, setAuthToken] = useSessionStorage(TOKEN_KEY)

  useEffect(() => {
    const payload: any = jwt.decode(token) || {}

    setAuth({
      token,
      clientId: payload['https://app.whispir.com/account_id'],
      userId: payload['https://app.whispir.com/user_id'],
    })
  }, [token])

  return {
    auth,
    setAuth,
    setAuthToken,
  }
}
