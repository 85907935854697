import { useState } from 'react'
import axios, { CancelToken } from 'axios'

import {
  IntegrationState,
  IntegrationResponseData,
  UseFacebookIntegrations,
  FacebookIntegrationResponseData,
  Integration,
  AvailableIntegrations,
} from './useFacebookIntegrations.types'

import Axios from 'config/AxiosConfig'
import { useAuth } from './useAuth'

export const useFbIntegrations = (
  defaultState: IntegrationState
): UseFacebookIntegrations => {
  const [facebookIntegrationState, setFacebookIntegrationState] =
    useState<IntegrationState>(defaultState)
  const { auth } = useAuth()

  const fetchCurrentFacebookIntegrations = async (
    cancelToken: CancelToken
  ): Promise<void> => {
    try {
      const { data: response } = await Axios.get('/integrations', {
        cancelToken: cancelToken,
        headers: {
          Authorization: `Bearer ${auth.token.replace(/"/g, '')}`,
        },
      })

      const pages = response.data.reduce(
        (pages: Integration[], page: IntegrationResponseData) => [
          ...pages,
          { id: page.id, ...page.attributes },
        ],
        []
      )

      setFacebookIntegrationState({
        ...facebookIntegrationState,
        current: pages,
      })
    } catch (error: any) {
      if (!axios.isCancel(error)) {
        setFacebookIntegrationState({ ...facebookIntegrationState, error })
      }
    }
  }

  const fetchAvailableFacebookIntegrations = async (
    accessToken: string
  ): Promise<void> => {
    try {
      const params = { accessToken }

      const { data: response } = await Axios.get('/facebook/pages', {
        params,
        headers: {
          Authorization: `Bearer ${auth.token.replace(/"/g, '')}`,
        },
      })

      const pages = response.data.reduce(
        (
          pages: AvailableIntegrations,
          page: FacebookIntegrationResponseData
        ) => {
          const { id, ...rest } = page
          return {
            ...pages,
            [id]: { ...rest },
          }
        },
        {}
      )

      setFacebookIntegrationState({
        ...facebookIntegrationState,
        available: pages,
      })
    } catch (error: any) {
      // console.error(error)
      setFacebookIntegrationState({ ...facebookIntegrationState, error })
    }
  }

  const addFacebookIntegration = async (pageId: string): Promise<void> => {
    try {
      const requestBody = {
        name: facebookIntegrationState.available[pageId].name,
        type: 'messenger',
        options: {
          pageId,
          channelToken: facebookIntegrationState.available[pageId].accessToken,
        },
      }

      const response = await Axios.post('/integrations', requestBody, {
        headers: {
          Authorization: `Bearer ${auth.token.replace(/"/g, '')}`,
          'content-type': 'text/json',
        },
      })

      const {
        id,
        attributes: { name, type, referenceId, appName, appReferenceId, url },
      } = response.data

      const newIntegration = {
        id,
        name,
        type,
        referenceId,
        appName,
        appReferenceId,
        url,
      }

      setFacebookIntegrationState({
        ...facebookIntegrationState,
        current: [...facebookIntegrationState.current, newIntegration],
      })
    } catch (error) {
      // console.error(error)
    }
  }

  return {
    facebookIntegrationState,
    fetchCurrentFacebookIntegrations,
    fetchAvailableFacebookIntegrations,
    addFacebookIntegration,
  }
}
