import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AccordionDetails from '@mui/material/AccordionDetails'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'

import { Icon, IconTypes } from 'components/Icon'

import { Summary, Titles, Accordion } from './AccordionItem.styles'

export type AccordionItemProps = {
  id: string
  title: string
  icon?: IconTypes
  completed?: boolean
  subTitle?: string
  children?: React.ReactNode
}

const AccordionItem = ({
  id,
  icon,
  title,
  completed,
  subTitle,
  children,
}: AccordionItemProps) => {
  return (
    <Accordion>
      <Summary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${id}-content`}
        id={`panel-${id}-header`}
      >
        {icon && (
          <>
            <Icon icon={icon} className='light' />
            {completed && (
              <Icon icon='CustomCheckCircleIcon' className='checkIcon_done' />
            )}
            <Divider orientation='vertical' flexItem />
          </>
        )}

        <Titles>
          <Typography className='main'>{title}</Typography>
          {subTitle && <Typography>{subTitle}</Typography>}
        </Titles>
      </Summary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}

export default AccordionItem
