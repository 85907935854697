import Alert from '@mui/material/Alert'
import { useGetConversations } from 'hooks/useConversations'
import ListItem from './ListItem'
import { Container, ErrorContainer, StyledList } from './Conversation.styles'
import Loading from './Loading'
import { ConversationProps } from './Conversation.types'

export default function Conversation({
  onClickConversation,
}: ConversationProps) {
  const { conversationsResult, loading, error } = useGetConversations()

  const renderConversationsError = () => {
    // TODO: Confirm what to show when error occurs.

    return (
      <ErrorContainer>
        <Alert severity='error'>Unable to load conversations!</Alert>
      </ErrorContainer>
    )
  }

  const renderConversations = () => {
    return (
      <StyledList>
        {conversationsResult?.items?.map((conversation) => {
          const {
            id,
            user: { avatarUrl, firstName, lastName },
          } = conversation

          const name = `${firstName} ${lastName}`

          return (
            <ListItem
              key={id}
              title={name}
              avatarUrl={avatarUrl}
              onClick={() =>
                onClickConversation({
                  id,
                  author: name,
                })
              }
            />
          )
        })}
      </StyledList>
    )
  }

  return (
    <Container data-testid='conversation'>
      {/* TODO: conversation status tabs */}
      {loading && <Loading />}
      {!loading && error && renderConversationsError()}
      {!loading && !error && renderConversations()}
    </Container>
  )
}
