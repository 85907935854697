import styled from 'styled-components'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { Theme } from '@mui/material'

export const Layout = styled(Box)(
  ({ theme }: { theme: Theme }) => `
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    font-family: ${theme.typography.fontFamily};
    display: flex;
  `
)

export const CustomDivider = styled(Divider)`
  margin: 1.5rem 0;
`

export const Sidebar = styled(Box)(
  ({ theme }: { theme: Theme }) => `
  width: 15rem;
  background-color: ${theme.palette.secondary.light};
  padding: 1.5rem;
  border-right: 1px solid #e7e7e9;

  & .iconContainer {
    svg {
      path {
        fill: ${theme.palette.primary.main};
      }
    }
  }
`
)

export const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
