import { Nav } from 'components/Navigation'
import IconTitle from 'components/IconTitle'

import { BaseLayoutProps } from './BaseLayout.types'
import {
  Layout,
  Sidebar,
  CustomDivider,
  MainContent,
} from './BaseLayout.styles'

export const BaseLayout = ({ children }: BaseLayoutProps) => {
  return (
    <Layout>
      {/* Sidebar component to be replaced with drawer functionality */}
      <Sidebar>
        <IconTitle icon='CustomChatBubbleIcon' title='Conversations' />
        <CustomDivider variant='middle' />
        <Nav></Nav>
      </Sidebar>
      <MainContent>
        {/* container for page level components */}
        {children}
      </MainContent>
    </Layout>
  )
}
