import { Theme } from '@mui/material'
import styled from 'styled-components'

export const StyledIconTitle = styled.div`
  display: flex;
  align-items: center;
  color: #1d1e2e;
  width: 100%;
  font-weight: 500;
`

export const StyledIcon = styled('div')<{
  theme: Theme
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: ${({ theme }) => theme.palette.primary.light};
  color: ${({ theme }) => theme.palette.primary.main};
  fill: white;
  border-radius: 4px;
  margin-right: 0.5rem;

  svg {
    width: 20px;
    height: 20px;
  }
`
