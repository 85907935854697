import Chip from '@mui/material/Chip'
import styled from 'styled-components'

// button
export const Button = styled.button.attrs((props) => ({
  clickable: !!props.onClick,
}))`
  display: flex;
  align-items: flex-start;
  width: 328px;
  height: 80px;
  padding: 1rem 1.5rem;
  border-radius: 1.5rem;
  background-color: ${(props) => props.theme.palette.secondary.light};
  border: none;
  &:hover {
    cursor: ${(props) => (props.clickable ? 'pointer' : 'cursor')};
  }
`

// logo
export const Logo = styled.img`
  width: 3rem;
  height: 3rem;
`

// typography
export const Titles = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  align-items: flex-start;
  flex-grow: 2;

  .name {
    font-weight: 600;
  }

  .MuiTypography-root:not(.name) {
    display: flex;
    font-size: 0.9rem;
    align-items: center;

    .success {
      color: #188142;
      font-size: 1rem;
      margin-right: 0.25rem;
    }
  }
`

export const Status = styled(Chip)`
  background-color: ${(props) => props.theme.palette.primary.light};
  border-radius: 0.25rem;
  color: ${(props) => props.theme.palette.primary.main};
  width: 42px;
  height: 16px;
  font-size: 0.75rem;
  &:hover {
    cursor: pointer;
  }
  .MuiChip-label {
    padding: 0 0.35rem;
  }
`
