import { MessageContentType } from 'hooks/useMessages.types'

export enum MessageBubbleAlignment {
  LEFT = 'left',
  RIGHT = 'right',
}

export interface MessageBubbleProps {
  content: string
  type: MessageContentType
  timestamp: string
  avatarIcon: JSX.Element
  align: MessageBubbleAlignment
}
