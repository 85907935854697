import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import styled from 'styled-components'

export const MessageAuthorContainer = styled(ListItemIcon)`
  min-width: 42px;
  margin-top: 15px;
`

export const MessageBubbleText = styled(ListItemText)`
  background: #1d1e2e;
  padding: 12px 16px 8px 16px;
  border-radius: 20px;
  color: #f6f6f7;
  flex-grow: 0;
  max-width: 50%;
  min-width: 15%;
  font-size: 1em;

  .MuiListItemText-secondary {
    display: inline;
    float: right;
    font-size: 0.8em;
    margin-top: 0.5em;
    color: #c3c3c8;
  }
`
