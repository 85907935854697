import { OnboardingContextProvider } from 'context/OnboardingContext'

import Header from './Header'
import Steps from './Steps'

import { Main } from './Onboarding.styles'
import { IntegrationContextProvider } from 'context/IntegrationContext'

const Onboarding = () => {
  return (
    <Main>
      <OnboardingContextProvider>
        <IntegrationContextProvider>
          <Header />
          <Steps />
        </IntegrationContextProvider>
      </OnboardingContextProvider>
    </Main>
  )
}

export default Onboarding
