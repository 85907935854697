import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@mui/material/CssBaseline'
import '@whispir/ui-lib-v2/lib/Assets/Fonts/fonts.css'
import { ThemeProvider } from 'styled-components'

import theme from 'styles/theme'
import App from 'pages/App'
import ApolloProviderWrapper from 'apollo/Provider'

if (process.env.REACT_APP_ENV === 'LOCAL') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser')
  worker.start()
}

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ApolloProviderWrapper>
        <App />
      </ApolloProviderWrapper>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
