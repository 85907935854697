import { MoreHoriz } from '@mui/icons-material'
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material'
import { MessageBoxHeaderProps } from './MessageBoxHeader.types'

const MessageBoxHeader = ({ author }: MessageBoxHeaderProps) => {
  return (
    <Box data-testid='messageBoxHeader' sx={{ flexGrow: 1 }}>
      <AppBar position='static'>
        <Toolbar>
          <Typography
            variant='h6'
            noWrap
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
          >
            {author}
          </Typography>
          <IconButton
            size='large'
            aria-label='display more actions'
            edge='end'
            color='inherit'
          >
            <MoreHoriz />
          </IconButton>
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default MessageBoxHeader
