import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

import { BootstrapDialog, CustomDialogTitle } from './Dialog.styles'
import { CustomDialogProps, DialogTitleProps } from './Dialog.types'

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props

  return (
    <CustomDialogTitle {...other}>
      {children}
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
    </CustomDialogTitle>
  )
}

export default function CustomDialog({
  open,
  onClose,
  header,
  content,
  actions,
  ...other
}: CustomDialogProps) {
  const mainProps = {
    open,
    onClose,
    ...other,
  }

  const titleProps = {
    onClose,
  }

  return (
    <BootstrapDialog {...mainProps} aria-labelledby='custom-dialog-title'>
      <BootstrapDialogTitle {...titleProps}>{header}</BootstrapDialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </BootstrapDialog>
  )
}
