export enum MessageContentType {
  TEXT = 'text',
}

type Message = {
  id: string
  author: {
    firstName: string
    lastName: string
    avatarUrl: string
  }
  content: {
    type: MessageContentType
    text: string
  }
  reference: {
    authUserId?: string
  }
  audit: {
    created: {
      by: string
      date: string
    }
  }
}

export interface PageInfo {
  next: string
}

export interface GetMessagesResult {
  items: Message[]
  pageInfo: PageInfo
}
