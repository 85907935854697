export enum IntegrationStatusTypes {
  FREE = 'Free',
  PAID = 'Paid',
  BETA = 'Beta',
}

export enum IntegrationName {
  MESSENGER = 'messenger',
}

export type IntegrationButtonProps = {
  name: IntegrationName
  logo?: string
  subTitle?: string
  installed?: boolean
  status: IntegrationStatusTypes
  onClick?: () => void
}
