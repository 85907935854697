import axios, { CancelToken } from 'axios'
import { useEffect } from 'react'
import { useAuth } from './useAuth'

// A custom hook to handle API requests required on page load
const useRequestCancel = (request: (src: CancelToken) => Promise<void>) => {
  const { auth } = useAuth()

  useEffect(() => {
    if (auth.token) {
      const source = axios.CancelToken.source()
      request(source.token)

      return () => {
        source.cancel()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  return { request }
}

export default useRequestCancel
