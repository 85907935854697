import { Button } from '@mui/material'

import integrationConfirmationImg from 'assets/Images/integration-confirmation.png'
import { IntegrationConfirmationDialogProps } from 'components/Dialog/Dialog.types'

import { StyledDialog, Content } from './IntegrationConfirmation.styles'

const IntegrationConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
}: IntegrationConfirmationDialogProps) => {
  const handleClose = () => {
    onClose()
  }

  const handleConfirm = () => {
    onConfirm()
  }

  const header = (
    <img src={integrationConfirmationImg} alt='confirm integration image' />
  )

  const content = (
    <Content>
      <h2>Confirm Integration</h2>
      <p>To complete the setup, please confirm the integration.</p>
    </Content>
  )

  const actions = (
    <>
      <Button variant='outlined' onClick={handleClose}>
        Cancel
      </Button>
      <Button variant='contained' onClick={handleConfirm}>
        Save Changes
      </Button>
    </>
  )

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      header={header}
      content={content}
      actions={actions}
      data-testid='confirmation_dialog'
    />
  )
}

export default IntegrationConfirmationDialog
