import { ApolloProvider } from '@apollo/client'
import { AUTH_TYPE } from 'aws-appsync-auth-link'
import { TOKEN_KEY } from 'hooks/useAuth'
import { getSessionItem } from 'hooks/useSessionStorage'
import { ReactNode } from 'react'
import createClient, { Config } from './Client'

const authToken = getSessionItem(TOKEN_KEY)
const ApolloProviderWrapper = ({ children }: { children?: ReactNode }) => {
  const url = process.env.REACT_APP_GRAPHQL_BASE_URL || ''
  const region = process.env.REACT_APP_GRAPHQL_REGION || 'ap-southeast-2'
  const config: Config = {
    url,
    subscriptionUrl: `${url}/realtime`,
    region,
    auth: {
      type: AUTH_TYPE.AWS_LAMBDA,
      token: `Bearer ${authToken}`,
    },
  }

  const client = createClient(config)

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default ApolloProviderWrapper
