import styled from 'styled-components'

export const TitleContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.1rem;
`

export const Title = styled.p`
  margin: 0;
`

export const TitleInfo = styled.p`
  margin: 0;
  color: #0007;
  font-size: 0.85rem;
`
