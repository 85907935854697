import React from 'react'
import { Box, Stack } from '@mui/material'
import { Container } from './Login.styles'
import { Button, TextField } from '@whispir/ui-lib-v2'

// Simple form to be able to provide access token to app
interface LoginProps {
  token: string
  onTextChange: (value: string) => void
  handleSubmit: () => void
}

const Login = ({ token, onTextChange, handleSubmit }: LoginProps) => {
  return (
    <Container>
      <Box
        component='div'
        sx={{
          p: 2,
          background: 'white',
          width: '50%',
          margin: '0 auto',
        }}
      >
        <h3>Submit Token</h3>

        <Stack spacing={2}>
          <TextField
            aria-label='access-token'
            label='Access Token'
            value={token}
            onChange={(e) => onTextChange(e)}
          />

          <Button
            aria-label='button-submit'
            size='medium'
            text='Submit'
            type='primary'
            variant='button'
            onClick={handleSubmit}
          />
        </Stack>
      </Box>
    </Container>
  )
}

export default Login
