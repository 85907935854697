import { gql } from '@apollo/client'

export const GET_MESSAGES = gql`
  query getMessages($conversationId: ID!, $size: Int!) {
    messages(
      input: { conversationId: $conversationId, pagination: { size: $size } }
    ) {
      items {
        id
        author {
          firstName
          lastName
          avatarUrl
        }
        content {
          type
          text
        }
        reference {
          authUserId
        }
        audit {
          created {
            date
          }
        }
      }
      pageInfo {
        next
      }
    }
  }
`

export const ON_CREATE_MESSAGE_SUBSCRIPTION = gql`
  subscription onCreateMessage($conversationId: ID!) {
    onCreateMessage(conversationId: $conversationId) {
      id
      author {
        firstName
        lastName
        avatarUrl
      }
      content {
        type
        text
      }
      reference {
        authUserId
      }
      audit {
        created {
          date
        }
      }
    }
  }
`
