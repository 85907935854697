import onBoardingImg from 'assets/Images/onboarding.png'
import {
  Container,
  HeaderImage,
  SubHeader,
  StyledIconTitle,
} from './Header.styles'

const Header = () => {
  return (
    <Container>
      <StyledIconTitle
        icon='CustomRocketLaunchIcon'
        title='Welcome to Conversations'
      ></StyledIconTitle>
      <SubHeader>
        <div>
          We recommend completing the following tasks to get the best out of
          Inbox.
        </div>
        <HeaderImage src={onBoardingImg} />
      </SubHeader>
    </Container>
  )
}

export default Header
