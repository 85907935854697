import { IntegrationContext } from 'context/IntegrationContext'
import useRequestCancel from 'hooks/useRequestCancel'
import { useContext } from 'react'

import ChannelStep from './ChannelStep'
import InboxStep from './InboxStep'

const Steps = () => {
  const { fetchCurrentFacebookIntegrations } = useContext(IntegrationContext)

  useRequestCancel(fetchCurrentFacebookIntegrations)

  return (
    <>
      <ChannelStep />
      <InboxStep />
    </>
  )
}

export default Steps
