import styled from 'styled-components'
import Dialog from '../Dialog'

export const StyledDialog = styled(Dialog)`
  & div[role='dialog'] {
    width: 35rem;
  }

  & .MuiDialogTitle-root {
    display: flex;
    justify-content: center;
    padding: 1rem 0;

    img {
      width: 8rem;
    }
  }
`

export const Content = styled.section`
  margin: 0 0 3rem 0;

  h2 {
    font-weight: 500;
    margin: 0;
  }
`
