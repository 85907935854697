import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const CustomCheckCircleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} data-testid='CustomCheckCircleIcon'>
      <circle cx='12' cy='12' r='12' fill='#F3F2F2'></circle>
      <circle cx='12' cy='12' r='10' fill='white'></circle>
      <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'></path>
    </SvgIcon>
  )
}

const CustomLaunchIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} data-testid='CustomLaunchIcon'>
      <path
        d='M14.413 20C14.2888 20 14.1638 19.9883 14.0405 19.965C13.5447 19.8717 13.1056 19.5984 12.8031 19.1959L11.5524 17.526L9.39672 18.9951C9.06174 19.2234 8.67011 19.3442 8.26431 19.3442C7.15524 19.3442 6.25365 18.4418 6.25365 17.3336V13.6505H2.0115C0.902425 13.6505 0 12.7481 0 11.639C0 10.9408 0.353304 10.3033 0.945754 9.93334L16.347 0.305808C16.6661 0.105825 17.0344 0 17.4102 0C18.1093 0 18.7476 0.352471 19.1176 0.943255C19.3867 1.37405 19.4834 1.88568 19.3884 2.3848L16.3886 18.3593C16.2103 19.3109 15.3787 20 14.413 20ZM13.803 18.446C13.9172 18.5984 14.0838 18.7018 14.2713 18.7368C14.3171 18.7451 14.3646 18.7493 14.4113 18.7493C14.7779 18.7493 15.0929 18.4885 15.1604 18.1285L18.1602 2.15315C18.1743 2.07983 18.1777 2.00567 18.1702 1.93234L9.74419 13.0272L13.803 18.446ZM7.50354 17.3344C7.50354 17.7535 7.84518 18.0952 8.26431 18.0952C8.41763 18.0952 8.56595 18.0493 8.69344 17.9627L10.8016 16.5253L8.64928 13.6497H7.50354V17.3344ZM17.0094 1.36572L1.6082 10.9932C1.38405 11.1332 1.25073 11.3749 1.25073 11.639C1.25073 12.059 1.59237 12.4006 2.01233 12.4006H8.65178L17.0486 1.34239C17.0361 1.34989 17.0227 1.35739 17.0094 1.36572Z'
        fill='#70707B'
      />
    </SvgIcon>
  )
}

const CustomRocketLaunchIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} data-testid='CustomRocketLaunchIcon'>
    <path
      transform='translate(-4,-4)'
      d='M16.0443 24.2525C15.5574 24.2525 15.1276 23.9422 14.9743 23.4809L14.1087 20.8867L11.1105 17.8876L8.51516 17.0221C8.23037 16.9274 7.99894 16.7276 7.86443 16.4579C7.72918 16.1889 7.70739 15.8831 7.80207 15.5984C7.85692 15.4323 7.9516 15.2798 8.07558 15.1559L8.24916 14.9823C9.20947 14.0222 10.4794 13.4933 11.8251 13.4933C12.3722 13.4933 12.9154 13.5819 13.4399 13.757L14.0531 13.9613L18.3865 9.62862C19.3776 8.63691 20.6911 8.00582 22.0849 7.85106L23.7065 7.67075C23.7275 7.66849 23.7486 7.66699 23.7696 7.66699C23.9199 7.66699 24.0612 7.72559 24.1679 7.83228C24.2896 7.95399 24.3482 8.12152 24.3294 8.29282L24.1491 9.91411C23.9943 11.3078 23.3631 12.621 22.3712 13.6127L18.0371 17.9454L18.2407 18.5585C18.8524 20.3924 18.3827 22.3803 17.0159 23.7477L16.8401 23.9227C16.7168 24.0459 16.5651 24.1398 16.3997 24.1947C16.284 24.233 16.1646 24.2525 16.0443 24.2525ZM16.0436 23.1256L16.2187 22.9505C17.2812 21.8875 17.6463 20.3413 17.1707 18.9153L17.1459 18.8402L15.2463 20.735L16.0436 23.1256ZM14.6016 19.7853L21.5732 12.8156C22.3848 12.0042 22.9017 10.9299 23.028 9.79014L23.1302 8.86906L22.2089 8.97124C21.0683 9.0982 19.9938 9.61434 19.1822 10.4257L12.2121 17.3955L14.6016 19.7853ZM11.8266 14.621C10.7807 14.621 9.79331 15.0319 9.04641 15.7794L8.87208 15.9537L11.2631 16.7509L13.1612 14.8524L13.0838 14.8268C12.675 14.6901 12.2519 14.621 11.8266 14.621Z'
      fill='#E7E7E9'
    />
    <path
      transform='translate(-4,-4)'
      d='M8.22944 24.3333C8.07915 24.3333 7.93713 24.2747 7.83119 24.168C7.68691 24.0245 7.63206 23.8134 7.68691 23.6173C7.84997 23.0381 8.69832 20.1133 9.42569 19.3883C9.81116 19.0021 10.3251 18.7692 10.8714 18.7332C10.8774 18.7332 10.9022 18.7324 10.9082 18.7324C11.2043 18.7324 11.4515 18.9638 11.4703 19.2591C11.4906 19.5686 11.2554 19.8376 10.945 19.8579C10.6715 19.8751 10.4153 19.9916 10.2222 20.1854C9.97272 20.4341 9.51586 21.5295 9.07027 22.9284C10.4686 22.4829 11.5642 22.0268 11.8137 21.7774C12.0241 21.5678 12.1443 21.2778 12.1436 20.981C12.1436 20.67 12.396 20.4176 12.7064 20.4168C13.0174 20.4168 13.2699 20.6693 13.2707 20.9795C13.2714 21.5738 13.0302 22.1553 12.6094 22.5745C11.8851 23.2988 8.96056 24.1485 8.38122 24.3115C8.33163 24.3265 8.28053 24.3333 8.22944 24.3333Z'
      fill='#E7E7E9'
    />
  </SvgIcon>
)

const CustomChatBubbleIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} data-testid='CustomChatBubbleIcon'>
    <path
      transform='translate(3, 1)'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M1.97531 3.95133C1.70258 3.95133 1.48148 4.17242 1.48148 4.44516V14.3217C1.48148 14.5944 1.70258 14.8155 1.97531 14.8155H3.20988C3.61898 14.8155 3.95062 15.1472 3.95062 15.5563V17.4717L6.3898 15.0325C6.52871 14.8936 6.71712 14.8155 6.91358 14.8155H15.5556C15.8283 14.8155 16.0494 14.5944 16.0494 14.3217V10.618C16.0494 10.2089 16.381 9.87726 16.7901 9.87726C17.1992 9.87726 17.5309 10.2089 17.5309 10.618V14.3217C17.5309 15.4126 16.6465 16.297 15.5556 16.297H7.2204L3.73366 19.7838C3.52181 19.9956 3.2032 20.059 2.92641 19.9443C2.64961 19.8297 2.46914 19.5596 2.46914 19.26V16.297H1.97531C0.884376 16.297 0 15.4126 0 14.3217V4.44516C0 3.35422 0.884376 2.46985 1.97531 2.46985H6.2963C6.7054 2.46985 7.03704 2.80149 7.03704 3.21059C7.03704 3.61969 6.7054 3.95133 6.2963 3.95133H1.97531Z'
      fill='black'
    />
    <path
      transform='translate(3, 1)'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.2275 0.216958C13.9382 -0.0723193 13.4692 -0.0723193 13.1799 0.216958L10.7108 2.68609C10.4215 2.97537 10.4215 3.44438 10.7108 3.73366L13.1799 6.2028C13.4692 6.49207 13.9382 6.49207 14.2275 6.2028C14.5168 5.91352 14.5168 5.44451 14.2275 5.15523L12.2821 3.20988L14.2275 1.26452C14.5168 0.975246 14.5168 0.506235 14.2275 0.216958Z'
      fill='black'
    />
    <path
      transform='translate(3, 1)'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M14.321 3.95133C16.6392 3.95133 18.5185 5.83063 18.5185 8.14886C18.5185 8.55796 18.8502 8.8896 19.2593 8.8896C19.6684 8.8896 20 8.55796 20 8.14886C20 5.01243 17.4574 2.46985 14.321 2.46985H11.2346C10.8255 2.46985 10.4938 2.80149 10.4938 3.21059C10.4938 3.61969 10.8255 3.95133 11.2346 3.95133H14.321Z'
      fill='black'
    />
    <path
      transform='translate(3, 1)'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.1411 0.216958C10.8518 -0.0723193 10.3828 -0.0723193 10.0935 0.216958L7.62437 2.68609C7.3351 2.97537 7.3351 3.44438 7.62437 3.73366L10.0935 6.2028C10.3828 6.49207 10.8518 6.49207 11.1411 6.2028C11.4304 5.91352 11.4304 5.44451 11.1411 5.15523L9.19572 3.20988L11.1411 1.26452C11.4304 0.975246 11.4304 0.506235 11.1411 0.216958Z'
      fill='black'
    />
  </SvgIcon>
)

const CustomInboxIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} data-testid='CustomInboxIcon'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 13.9893C0 12.8729 0.905085 11.9678 2.02156 11.9678H5.4186C5.66696 11.9678 5.89626 12.1009 6.0194 12.3166C6.83527 13.7456 8.35451 14.6276 10 14.6276C11.6455 14.6276 13.1647 13.7456 13.9806 12.3166C14.1037 12.1009 14.333 11.9678 14.5814 11.9678H17.9784C19.0949 11.9678 20 12.8729 20 13.9893V17.9786C20 19.095 19.0949 20.0001 17.9784 20.0001H2.02156C0.905085 20.0001 0 19.095 0 17.9786V13.9893ZM2.02156 13.3514C1.66925 13.3514 1.38365 13.637 1.38365 13.9893V17.9786C1.38365 18.3309 1.66925 18.6165 2.02156 18.6165H17.9784C18.3307 18.6165 18.6164 18.3309 18.6164 17.9786V13.9893C18.6164 13.637 18.3307 13.3514 17.9784 13.3514H14.9669C13.8658 15.0054 12.0049 16.0112 10 16.0112C7.99509 16.0112 6.13415 15.0054 5.03312 13.3514H2.02156Z'
      fill='black'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.0001 0C10.3821 0 10.6919 0.30974 10.6919 0.691824V10C10.6919 10.3821 10.3821 10.6918 10.0001 10.6918C9.61797 10.6918 9.30823 10.3821 9.30823 10V0.691824C9.30823 0.30974 9.61797 0 10.0001 0Z'
      fill='black'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M6.13527 6.90823C6.37395 6.60987 6.80931 6.5615 7.10767 6.80019L9.99984 9.11392L12.892 6.80019C13.1904 6.5615 13.6257 6.60987 13.8644 6.90823C14.1031 7.20659 14.0547 7.64195 13.7564 7.88063L10.432 10.5401C10.1794 10.7422 9.82033 10.7422 9.56766 10.5401L6.24331 7.88063C5.94496 7.64195 5.89658 7.20659 6.13527 6.90823Z'
      fill='black'
    />
  </SvgIcon>
)

const CustomMessengerIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} data-testid='CustomMessengerIcon'>
    <path
      d='M0 16.1787C0 7.34215 7.16344 0.178711 16 0.178711C24.8366 0.178711 32 7.34215 32 16.1787C32 25.0153 24.8366 32.1787 16 32.1787C7.16344 32.1787 0 25.0153 0 16.1787Z'
      fill='#008EFA'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M24.0711 12.4402C24.5389 13.4691 24.7727 14.5381 24.7727 15.6471C24.7727 16.7789 24.5389 17.8536 24.0711 18.8711C23.6262 19.8543 22.9988 20.7232 22.1888 21.4778C21.3788 22.2323 20.4491 22.8211 19.3995 23.2441C18.3158 23.6672 17.1864 23.8787 16.0114 23.8787C15.0759 23.8787 14.1633 23.7415 13.2734 23.4671L10.7409 24.9076C10.6382 24.9648 10.5355 24.9934 10.4328 24.9934C10.3302 24.9934 10.2389 24.9705 10.159 24.9248C9.96511 24.8219 9.85103 24.6618 9.81681 24.4446L9.42323 21.0833C8.72734 20.3288 8.19116 19.4856 7.8147 18.5538C7.43823 17.6221 7.25 16.6532 7.25 15.6471C7.25 14.5381 7.48386 13.4691 7.95159 12.4402C8.39651 11.4569 9.02394 10.5823 9.83392 9.81635C10.6439 9.06178 11.5736 8.47872 12.6232 8.06714C13.6955 7.64412 14.8249 7.43262 16.0114 7.43262C17.1978 7.43262 18.3272 7.64412 19.3995 8.06714C20.4491 8.47872 21.3788 9.06178 22.1888 9.81635C22.9988 10.5823 23.6262 11.4569 24.0711 12.4402ZM15.1801 13.3208L10.7963 17.9111L14.736 15.7284L16.8425 17.9561L21.1693 13.3208L17.3321 15.4472L15.1801 13.3208Z'
      fill='white'
    />
  </SvgIcon>
)

export {
  CustomCheckCircleIcon,
  CustomLaunchIcon,
  CustomRocketLaunchIcon,
  CustomChatBubbleIcon,
  CustomInboxIcon,
  CustomMessengerIcon,
}
