import { useState, useEffect } from 'react'

export function getSessionItem(key: string, defaultValue = '') {
  const stored = sessionStorage.getItem(key)
  if (!stored) {
    return defaultValue
  }
  return JSON.parse(stored)
}

export function useSessionStorage(key: string, defaultValue = '') {
  const [value, setValue] = useState(getSessionItem(key, defaultValue))

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}
