// hook for graphql requests
// include state for loading, error & data

import { useLazyQuery } from '@apollo/client'
import {
  ConversationStatus,
  GET_CONVERSATIONS,
  ON_CREATE_CONVERSATION_SUBSCRIPTION,
} from 'apollo/gql/conversations'
import { useEffect, useState } from 'react'
import { useAuth } from './useAuth'
import { GetConversationResult } from './useConversations.types'

export const useGetConversations = (
  status: ConversationStatus = ConversationStatus.UNREAD
) => {
  // should use useReducer for complex state
  const [conversationsResult, setConversationsResult] =
    useState<GetConversationResult>()
  const [getConversations, { loading, error, data, subscribeToMore }] =
    useLazyQuery(GET_CONVERSATIONS, {
      variables: { status, size: 10 },
    })

  const { auth } = useAuth()

  if (error) {
    console.error('error', error)
  }

  useEffect(() => {
    getConversations()
  }, [status, getConversations])

  useEffect(() => {
    if (auth.clientId) {
      subscribeToMore({
        document: ON_CREATE_CONVERSATION_SUBSCRIPTION,
        variables: {
          clientId: auth.clientId,
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev
          }

          const newConversation = subscriptionData.data.onCreateConversation

          return {
            ...prev,
            conversations: {
              ...prev.conversations,
              items: [newConversation, ...prev.conversations.items],
            },
          }
        },
      })
    }
  }, [status, auth, subscribeToMore])

  useEffect(() => {
    if (data) {
      setConversationsResult(data.conversations)
    }
  }, [data, loading])

  return {
    conversationsResult,
    loading,
    error,
  }
}
