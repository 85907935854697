import styled from 'styled-components'
import Dialog from '../Dialog'

export const Content = styled.section`
  margin-bottom: 3rem;

  header {
    display: flex;
    justify-content: space-between;

    h2 {
      margin: 0;
      font-weight: 500;
    }
  }
`

export const StyledDialog = styled(Dialog)`
  & .MuiDialogTitle-root {
    padding: 0;

    img {
      width: 100%;
    }
  }
`
