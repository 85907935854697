import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'

import { CustomDialog } from 'components/Dialog'
import { FacebookConnectDialogProps } from 'components/Dialog/Dialog.types'
import modalHeader from 'assets/Images/facebook-connect.jpg'

const FacebookConnectDialog = ({
  open,
  onClose,
}: FacebookConnectDialogProps) => {
  const handleClose = () => {
    onClose()
  }

  const Header = () => <img src={modalHeader} alt='facebook connect' />

  const Content = () => (
    <div>
      <h2>Facebook Messenger</h2>
      <p>
        Talk to your customers over Facebook Messenger in just a few minutes.
      </p>
      <p>
        Manage and enable all your customer communication - Connect all your
        messaging channels into the same place.
      </p>
    </div>
  )

  const Actions = () => (
    <>
      <Button variant='outlined' onClick={handleClose}>
        Cancel
      </Button>
      <Button
        variant='contained'
        component={Link}
        to='/inbox/channel/facebook-messenger'
      >
        Connect
      </Button>
    </>
  )

  return (
    <CustomDialog
      open={open}
      onClose={handleClose}
      header={<Header />}
      content={<Content />}
      actions={<Actions />}
      data-testid='messenger_dialog'
    />
  )
}

export default FacebookConnectDialog
