import styled from 'styled-components'

export const Container = styled.div`
  font-size: 0.9rem;
  display: flex;

  svg {
    margin: 0.5rem 0.5rem 0.5rem 0;
  }
`

export const Content = styled.div`
  padding-left: 0.5rem;
  p {
    font-weight: bold;
    margin: 0.25rem 0;
  }
  ol {
    margin: 0.5em 0;
    padding: 0 1rem;
  }
`
