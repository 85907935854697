import { Dispatch, useState } from 'react'

export type CompletedSteps = { channel: boolean; inbox: boolean }

export type StepContext = {
  completedSteps: CompletedSteps
  setCompletedSteps: Dispatch<React.SetStateAction<CompletedSteps>>
}

const useOnboarding = (defaultState: CompletedSteps): StepContext => {
  const [completedSteps, setCompletedSteps] =
    useState<CompletedSteps>(defaultState)

  return { completedSteps, setCompletedSteps }
}

export default useOnboarding
