import { Avatar, Container, Title } from './Loading.styles'

export default function Loading() {
  return (
    <>
      {[...Array(5).keys()].map((i) => (
        <Container role='loading' key={i}>
          <Avatar animation='wave' variant='circular' />
          <Title animation='wave' />
        </Container>
      ))}
    </>
  )
}
