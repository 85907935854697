import { createElement } from 'react'

import * as MuiIcons from './Icons'
import * as CustomIcons from './CustomIcons'

const Icons = { ...MuiIcons, ...CustomIcons }

export const allIcons = [
  'RocketLaunchIcon',
  'ChatBubbleOutlineIcon',
  'SendOutlinedIcon',
  'MoveToInboxOutlinedIcon',
  'DoneIcon',
  'CustomCheckCircleIcon',
  'CustomLaunchIcon',
  'CustomRocketLaunchIcon',
  'CustomChatBubbleIcon',
  'CustomInboxIcon',
  'CustomMessengerIcon',
] as const

export type IconTypes = typeof allIcons[number]

type IconProps = {
  icon: IconTypes
  className?: string
  [key: string]: unknown
}

export const Icon = (props: IconProps) => {
  const { icon, className = '', ...rest } = props
  const element = createElement(Icons[icon], {
    className: `Icon ${className}`,
    ...rest,
  })
  return element
}
