import Conversation from 'components/Conversation'
import { Header } from 'components/Header'
import MessageBox from 'components/Messaging'
import Onboarding from 'components/Onboarding'
import { useState } from 'react'

import { Container, Sidebar } from './Inbox.styles'

export type ConversationDetails = {
  id: string
  author: string
}

const Inbox = () => {
  const [conversation, setConversation] = useState<ConversationDetails>()

  const handleClickConversation = (conversation: ConversationDetails) =>
    setConversation(conversation)

  return (
    <>
      <Header title='Inbox' />
      <Container>
        <Sidebar>
          <Conversation onClickConversation={handleClickConversation} />
        </Sidebar>
        {/* TODO: temporary state changes until we finalise what is required here */}
        {!conversation && <Onboarding />}
        {conversation && <MessageBox conversation={conversation} />}
      </Container>
    </>
  )
}

export default Inbox
