import { useState } from 'react'
import { getSessionItem } from 'hooks/useSessionStorage'
import Login from 'pages/Login'

import Main from './Main'
import { TOKEN_KEY } from 'hooks/useAuth'

function DeveloperMain() {
  const [token, setToken] = useState<string>('')

  const isLoggedIn = getSessionItem(TOKEN_KEY)

  const onTextChange = (value: string) => setToken(value)
  const handleSubmit = () => {
    sessionStorage.setItem(TOKEN_KEY, JSON.stringify(token))
    window.location.replace('/inbox')
  }

  return (
    <>
      {isLoggedIn && <Main />}

      {!isLoggedIn && (
        <Login
          token={token}
          onTextChange={onTextChange}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  )
}

export default DeveloperMain
