import { createTheme } from '@mui/material'

export const palette = {
  primary: {
    main: '#1515FF',
    light: '#E3E3FF',
    dark: '#1A1A4F',
  },
  secondary: {
    main: '#4D4DFF',
    light: '#F6F6F7',
    dark: '#70707B',
  },
}

const theme = createTheme({
  palette,
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          textTransform: 'none',
        },
      },
    },
  },
})

export default theme
