import { useContext, useEffect, useState } from 'react'

import { AccordionItem, AccordionItemProps } from 'components/AccordionItem'
import { IntegrationButton } from 'components/Onboarding/IntegrationButton'
import { FacebookConnectDialog } from 'components/Dialog'
import useFetchChannels from 'hooks/useFetchChannels'
import { OnboardingContext } from 'context/OnboardingContext'
import { IntegrationContext } from 'context/IntegrationContext'
import { IntegrationName } from 'components/Onboarding/IntegrationButton/IntegrationButton.types'

const ChannelStep = () => {
  // STATE
  const { completedSteps, setCompletedSteps } = useContext(OnboardingContext)
  const { facebookIntegrationState: integrations } =
    useContext(IntegrationContext)

  const [dialogType, setDialogType] = useState<IntegrationName | null>()

  // HOOKS
  const [channels] = useFetchChannels()

  const isChannelSetup = (type: string) =>
    integrations.current.some((integration) => integration.type === type)

  const clickHandler = (type: IntegrationName): void => {
    setDialogType(type)
  }

  const handleModalClose = () => setDialogType(null)

  useEffect(() => {
    setCompletedSteps({
      ...completedSteps,
      channel: !!integrations.current.length,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations.current])

  const accordionProps: AccordionItemProps = {
    id: 'channel',
    icon: 'CustomLaunchIcon',
    title: 'Add a channel',
    subTitle: 'Setup the communication channels you use.',
    ...(completedSteps.channel && { completed: completedSteps.channel }),
  }

  return (
    <>
      <AccordionItem {...accordionProps}>
        {channels.map((channel) => (
          <IntegrationButton
            key={channel.type}
            logo={channel.logo}
            name={channel.type}
            subTitle={channel.subTitle}
            status={channel.status}
            {...(isChannelSetup(channel.type) && {
              installed: true,
            })}
            onClick={() => clickHandler(channel.type)}
          ></IntegrationButton>
        ))}
      </AccordionItem>
      {dialogType === IntegrationName.MESSENGER && (
        <FacebookConnectDialog
          open
          onClose={handleModalClose}
        ></FacebookConnectDialog>
      )}
    </>
  )
}

export default ChannelStep
