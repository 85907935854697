import { ReactNode } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Inbox, MessengerIntegration } from '../pages'

const prefix = process.env.PUBLIC_URL || ''

type RouteType = {
  path: string
  component: ReactNode
}

export const routes: RouteType[] = [
  {
    path: `${prefix}`,
    component: <Navigate replace to='/inbox' />,
  },
  {
    path: `${prefix}/inbox`,
    component: <Inbox />,
  },
  {
    path: `${prefix}/inbox/channel/facebook-messenger`,
    component: <MessengerIntegration />,
  },
  {
    path: `*`,
    component: <Navigate replace to='/inbox' />,
  },
]

const getRoutes = (routeList: RouteType[]) =>
  routeList.map((route, index) => getRoute(route, index))

const getRoute = (route: RouteType, index: number) => (
  <Route path={route.path} key={`key-${index}`} element={route.component} />
)

const RouteConfigs = () => {
  return <Routes>{getRoutes(routes)}</Routes>
}

export default RouteConfigs
