import mtAccordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import styled from 'styled-components'

export const Titles = styled.div`
  display: flex;
  flex-direction: column;

  .main {
    font-weight: 600;
  }

  .MuiTypography-root:not(.main) {
    font-size: 0.9rem;
  }
`

export const Summary = styled(AccordionSummary)`
  min-height: 93px;
  padding: 1.5rem;

  .light {
    color: ${(props) => props.theme.palette.secondary.dark};

    path {
      fill: #70707b;
    }
  }

  .checkIcon_done {
    color: #4bca81;
    position: absolute;
    top: 23px;
    left: 8px;
  }

  .MuiAccordionSummary-content {
    align-items: center;
    height: 100%;
    margin: 0;
    position: relative;

    .MuiDivider-root {
      margin: 0 1rem;
    }
  }
`

export const Accordion = styled(mtAccordion)`
  margin-bottom: 1rem;

  &.MuiPaper-rounded {
    border: 1px #e7e7e9 solid;
    border-radius: 0.5rem;
    box-shadow: none;

    &::before {
      background-color: transparent;
    }
  }

  &.Mui-expanded {
    margin: 0 0 1rem 0;
  }

  .MuiAccordionDetails-root {
    padding-left: 5rem;
  }
`
