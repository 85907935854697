import styled from 'styled-components'
import Skeleton from '@mui/material/Skeleton'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;

  .loading-avatar {
    width: 40px;
    height: 40px;
    margin-right: 0.5rem;
  }

  .loading-title {
    flex: 1;
  }
`

export const Avatar = styled(Skeleton)`
  width: 40px;
  height: 40px;
  margin-right: 0.5rem;
`

export const Title = styled(Skeleton)`
  flex: 1;
`
