import styled from 'styled-components'

interface StepProps {
  disabled?: boolean
}

export const StyledStep = styled.li.attrs<StepProps>((props) => ({
  'aria-disabled': props.disabled,
}))<StepProps>`
  display: flex;
  padding: 1rem 0rem 1.5rem 0rem;
  counter-increment: item;
  border-bottom: 1px solid #e7e7e9;
  font-size: 0.775rem;
  color: #343a40;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  .MuiInput-root,
  .MuiButton-root {
    align-self: center;
  }
  p {
    margin: 0.75rem 0;
  }

  /* Step Label */
  ::before {
    position: absolute;
    content: 'Step ' counter(item);
    font-weight: bold;
    color: #70707b;
  }
`

export const StepDetails = styled.div`
  flex-grow: 1;
  padding-top: 1rem;

  .title {
    font-size: 1.075rem;
  }

  .sub-title {
    font-size: 0.875rem;
  }
`

export const StyledTitle = styled.h3`
  font-size: 1.075rem;
`

export const StyledText = styled.p`
  font-size: 0.875rem;
`
