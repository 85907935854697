import { ReactNode } from 'react'
import { StepDetails, StyledTitle, StyledText, StyledStep } from './Step.styles'

type StepProps = {
  title: string
  text: string
  disabled?: boolean
  children: ReactNode
}

const Step = ({ title, text, disabled = false, children }: StepProps) => (
  <StyledStep disabled={disabled}>
    <StepDetails>
      <StyledTitle>{title}</StyledTitle>
      <StyledText>{text}</StyledText>
    </StepDetails>
    {children}
  </StyledStep>
)

export default Step
