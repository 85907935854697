import { useEffect, useState } from 'react'

export interface MessengerState {
  isSdkLoaded: boolean
  isLoggedIn: boolean
  authToken?: string
  username?: string
}

export interface MessengerProps {
  appId: string
  version: string
}

type AuthHookProps = {
  state: MessengerState
  login: () => void
  logout: () => void
}

const getWindow = (): any => {
  return window as any
}

const useFacebookAuth = (): AuthHookProps => {
  const [state, setState] = useState<MessengerState>({
    isLoggedIn: false,
    isSdkLoaded: false,
  })

  const setFacekbookInit = ({ version, appId }: MessengerProps): void => {
    getWindow().fbAsyncInit = () => {
      getWindow().FB.init({
        version: `v${version}`,
        appId: `${appId}`,
        cookie: true, // Enable cookies to allow the server to access the session.
        xfbml: true,
        status: true,
        localStorage: false,
      })

      setState({
        isLoggedIn: false,
        isSdkLoaded: true,
      })
    }
  }

  // attach SDK
  const loadSdkAsynchronously = (): void => {
    const scriptId = 'fb-sdk'
    const rootId = 'fb-root'
    ;((doc: Document, script: string) => {
      // if script does not already exist
      const newScriptElement = doc.createElement(script) as HTMLScriptElement

      newScriptElement.id = scriptId
      newScriptElement.src = `https://connect.facebook.net/en_US/sdk.js`
      doc.head.appendChild(newScriptElement)

      let fbRoot = doc.getElementById(rootId)
      if (!fbRoot) {
        fbRoot = doc.createElement('div')
        fbRoot.id = rootId
        doc.body.appendChild(fbRoot)
      }
    })(document, 'script')
  }

  const setUserDetails = (authToken: string): void => {
    getWindow().FB.api(
      '/me',
      { fields: 'name' },
      (resp: { id: string; name: string }) =>
        setState({
          ...state,
          isLoggedIn: true,
          authToken: authToken,
          username: resp.name,
        })
    )
  }

  useEffect(() => {
    setupFacebook()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setupFacebook = () => {
    if (document.getElementById('fb-sdk')) {
      setState({
        isLoggedIn: false,
        isSdkLoaded: true,
      })
      return
    }
    setFacekbookInit({
      appId: process.env.REACT_APP_FB_APP_ID || 'APP_ID',
      version: process.env.REACT_APP_FB_API_VERSION || '12.0',
    })
    loadSdkAsynchronously()
  }

  const login = (): void => {
    getWindow().FB.login(
      (resp: any) => {
        try {
          setUserDetails(resp.authResponse.accessToken)
        } catch (error) {
          setState({
            isLoggedIn: false,
            isSdkLoaded: true,
          })
        }
      },
      {
        scope: 'pages_messaging,pages_manage_metadata,pages_show_list',
      }
    )
  }

  const logout = (): void =>
    getWindow().FB.logout(() => {
      setState({
        isLoggedIn: false,
        isSdkLoaded: true,
      })
    })

  return { state, login, logout }
}

export default useFacebookAuth
