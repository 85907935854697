import styled from 'styled-components'
import IconTitle from '../../IconTitle'

export const HeaderImage = styled.img`
  display: none;

  ${(props) => props.theme.breakpoints.up('md')} {
    max-width: 175px;
    display: flex;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const SubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0 1rem 0;

  ${(props) => props.theme.breakpoints.up('md')} {
    padding: 1.5rem 1.5rem 0 2.5rem;
  }
  div {
    font-size: 1rem;

    ${(props) => props.theme.breakpoints.up('md')} {
      padding-right: 1rem;
    }
  }
`

export const StyledIconTitle = styled(IconTitle)(
  ({ theme: { palette } }) => `
    & .iconContainer {
      background: ${palette.secondary.main};
      color: ${palette.secondary.light};

      svg {
        path {
          fill: #fff;
        }
      }
    }
  `
)
