export const capitalise = (src = ''): string =>
  src.replace(/(?:^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase())

export const getInitials = (src = '', maxChar?: number) => {
  const words = src.match(/\b(\w+)\b/g) as string[]

  let initial = ''

  for (let i = 0; i < words?.length; i++) {
    if (maxChar && i >= maxChar) break

    initial += words[i][0]
  }

  return initial.toUpperCase()
}

export const toColor = (src: string, saturation: number, lightness: number) => {
  let hash = 0

  for (let i = 0; i < src.length; i++) {
    hash = src.charCodeAt(i) + ((hash << 5) - hash)
  }

  const hue = hash % 360

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`
}
