import Main from 'entryPoints/Main'
import DeveloperMain from 'entryPoints/DeveloperMain'

function App() {
  const BUILD_MODE = process.env.REACT_APP_BUILD_MODE || 'DEV'
  return (
    <>
      {BUILD_MODE === 'PROD' && <Main />}
      {BUILD_MODE === 'DEV' && <DeveloperMain />}
    </>
  )
}

export default App
