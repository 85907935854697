import AppBar from '@mui/material/AppBar'
import styled from 'styled-components'

export const StyledHeader = styled(AppBar)`
  position: relative;
  background: #ffffff;
  box-shadow: none;
  border-bottom: 1px solid #e7e7e9;
  color: black;
  padding: 1.5rem 2rem;
`
