import styled from 'styled-components'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'

export const BootstrapDialog = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 1rem;
  }
  & .MuiDialogContent-root {
    padding: 0.75rem 1.5rem;
  }

  & .MuiDialogActions-root {
    padding: 0.75rem 1.5rem 1.5rem;

    .MuiButton-root {
      margin-left: 1rem;
    }
  }
`

export const CustomDialogTitle = styled(DialogTitle)`
  padding: 5px;
`
