// hook for graphql requests
// include state for loading, error & data

import { useLazyQuery } from '@apollo/client'
import {
  GET_MESSAGES,
  ON_CREATE_MESSAGE_SUBSCRIPTION,
} from 'apollo/gql/messages'
import { useEffect, useState } from 'react'
import { GetMessagesResult } from './useMessages.types'

export const useGetMessages = (conversationId: string) => {
  // should ue useReducer for complex state
  const [messagesResult, setMessagesResult] = useState<GetMessagesResult>()
  const [getMessages, { loading, error, data, subscribeToMore }] = useLazyQuery(
    GET_MESSAGES,
    {
      variables: { conversationId, size: 10 },
    }
  )

  useEffect(() => {
    getMessages()
  }, [conversationId, getMessages])

  useEffect(() => {
    if (data) {
      setMessagesResult(data.messages)
    }
  }, [data, loading])

  useEffect(() => {
    subscribeToMore({
      document: ON_CREATE_MESSAGE_SUBSCRIPTION,
      variables: {
        conversationId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return prev
        }

        const newMessage = subscriptionData.data.onCreateMessage

        return {
          ...prev,
          messages: {
            ...prev.messages,
            items: [...prev.messages.items, newMessage],
          },
        }
      },
    })
  }, [conversationId, subscribeToMore])

  return {
    messagesResult,
    loading,
    error,
  }
}
