import { BrowserRouter } from 'react-router-dom'

import { BaseLayout } from 'components/BaseLayout'
// import { Header } from 'components/Header'
import Router from 'utils/RouteConfig'

const Main = () => {
  return (
    <BaseLayout>
      <BrowserRouter>
        {/* <Header></Header> */}
        <Router />
      </BrowserRouter>
    </BaseLayout>
  )
}

export default Main
