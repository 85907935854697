import { Container, ListItem, MessageList } from './MessageBox.styles'
import MessageBubble from './MessageBubble'
import Alert from '@mui/material/Alert'
import Header from './MessageBoxHeader'
import { MessageBoxProps } from './MessageBox.types'
import { useGetMessages } from 'hooks/useMessages'
import { Icon } from 'components/Icon'
import { useAuth } from 'hooks/useAuth'
import { DateTime } from 'luxon'
import Loading from './Loading'
import { MessageBubbleAlignment } from './MessageBubble/MessageBubble.types'

const MessageBox = ({ conversation }: MessageBoxProps) => {
  const { auth } = useAuth()
  const { messagesResult, loading, error } = useGetMessages(conversation.id)

  const renderError = () => (
    <Alert severity='error'>Unable to load messages!</Alert>
  )

  const renderMessages = () => {
    return (
      <MessageList data-testid='messageList'>
        {messagesResult?.items?.map(({ id, reference, content, audit }) => {
          const alignment =
            auth.userId === reference.authUserId
              ? MessageBubbleAlignment.RIGHT
              : MessageBubbleAlignment.LEFT

          const dt = DateTime.fromISO(audit.created.date)
          const timestamp = dt.toLocaleString(DateTime.TIME_SIMPLE)

          return (
            <ListItem key={id}>
              <MessageBubble
                /* @TODO: Change this to the profile pic or a different social icon when we implement them */
                avatarIcon={
                  <Icon
                    icon='CustomMessengerIcon'
                    viewBox=''
                    className='messenger-icon'
                  />
                }
                align={alignment}
                content={content.text}
                type={content.type}
                timestamp={timestamp}
              />
            </ListItem>
          )
        })}
      </MessageList>
    )
  }

  return (
    <Container data-testid='messageBox'>
      <Header author={conversation.author} />
      {loading && <Loading />}
      {!loading && error && renderError()}
      {!loading && !error && renderMessages()}
    </Container>
  )
}

export default MessageBox
