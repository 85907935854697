import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  Chip,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'

import {
  FacebookAuthDialog,
  IntegrationConfirmationDialog,
} from 'components/Dialog'
import { IntegrationContext } from 'context/IntegrationContext'
import useFacebookAuth, { MessengerState } from 'hooks/useFacebookAuth'

import {
  StepsList,
  AuthStatus,
  ControlContainer,
} from './IntegrationSteps.styles'
import Step from './Step/Step'

const IntegrationSteps = () => {
  const [formData, setFormData] = useState({ pageId: '', name: '' })
  const [openAuthDialog, setOpenAuthDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const { state: authState, login } = useFacebookAuth()
  const {
    facebookIntegrationState: integrationState,
    fetchAvailableFacebookIntegrations,
    addFacebookIntegration,
  } = useContext(IntegrationContext)
  const navigate = useNavigate()

  const isUserLoggedIn = (state: MessengerState) => state.isLoggedIn

  const handleAuthModalClose = () => {
    setOpenAuthDialog(false)
  }
  const handleAuthOnConfirm = () => {
    setOpenAuthDialog(false)
    login()
  }

  const handleConfirmModalClose = () => setOpenConfirmDialog(false)

  const handleFieldChange = (fieldName: string, value: string) => {
    setFormData({ ...formData, [fieldName]: value })
  }

  const submit = () => {
    addFacebookIntegration(formData.pageId)
    navigate('/inbox')
  }

  useEffect(() => {
    if (authState.authToken) {
      fetchAvailableFacebookIntegrations(authState.authToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.authToken])

  return (
    <>
      <StepsList>
        <Step
          title='Authentication'
          text='To get started, authenticate your Facebook account.'
        >
          {!isUserLoggedIn(authState) ? (
            <Button variant='contained' onClick={() => setOpenAuthDialog(true)}>
              Authenticate Facebook account
            </Button>
          ) : (
            <AuthStatus>
              Authenticated to facebook as:{' '}
              <Chip label={`${authState.username}`} />
            </AuthStatus>
          )}
        </Step>
        <Step
          disabled={!isUserLoggedIn(authState)}
          title='Choose a name'
          text='Name this integration so you can easily find it in your Whispir
            Conversations app.'
        >
          <ControlContainer>
            <FormControl sx={{ m: 1, minWidth: 300 }}>
              <TextField
                data-testid='integrationNameInput'
                disabled={!isUserLoggedIn(authState)}
                placeholder='Messenger'
                variant='outlined'
                onChange={(e) => handleFieldChange('name', e.target.value)}
              />
            </FormControl>
          </ControlContainer>
        </Step>
        <Step
          disabled={!isUserLoggedIn(authState)}
          title='Select a Facebook page'
          text='Select the Facebook business page that you want to connect.'
        >
          <ControlContainer>
            <FormControl sx={{ m: 1, minWidth: 300 }}>
              <InputLabel id='facebook-pages-label'>Page</InputLabel>
              <Select
                data-testid='facebookPageSelect'
                label='Page'
                labelId='facebook-pages-label'
                value={formData.pageId}
                disabled={!isUserLoggedIn(authState)}
                onChange={(e) => handleFieldChange('pageId', e.target.value)}
              >
                {integrationState.available &&
                  Object.keys(integrationState.available).map((id: string) => {
                    const { name } = integrationState.available[id]
                    return (
                      <MenuItem key={id} value={id}>
                        {name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </ControlContainer>
        </Step>

        <Step
          disabled={!isUserLoggedIn(authState)}
          title='Confirm integration'
          text='To complete the setup, connect your Facebook page to Whispir
            Conversations.'
        >
          <Button
            disabled={!isUserLoggedIn(authState)}
            variant='contained'
            onClick={() => setOpenConfirmDialog(true)}
          >
            Connect Facebook page
          </Button>
        </Step>
      </StepsList>
      <FacebookAuthDialog
        open={openAuthDialog}
        onClose={handleAuthModalClose}
        onConfirm={handleAuthOnConfirm}
      ></FacebookAuthDialog>
      <IntegrationConfirmationDialog
        open={openConfirmDialog}
        onClose={handleConfirmModalClose}
        onConfirm={submit}
      ></IntegrationConfirmationDialog>
    </>
  )
}

export default IntegrationSteps
