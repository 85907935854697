import { Avatar, Container, LoadingBox, Bubble } from './Loading.styles'

export default function Loading() {
  return (
    <Container>
      {[...Array(3).keys()].map((i) => (
        <LoadingBox role='loading' key={i}>
          <Avatar animation='wave' variant='circular' />
          <Bubble animation='wave' />
        </LoadingBox>
      ))}
    </Container>
  )
}
