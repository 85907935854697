import Typography from '@mui/material/Typography'

import { Icon } from 'components/Icon'
import { capitalise } from 'utils/StringUtils'

import { Logo, Titles, Button, Status } from './IntegrationButton.styles'
import { IntegrationButtonProps } from './IntegrationButton.types'

const IntegrationButton = ({
  logo,
  name,
  subTitle,
  installed = false,
  status,
  onClick,
}: IntegrationButtonProps) => {
  return (
    <Button
      {...(!installed && { onClick })}
      data-testid={`${name}_integrationBtn`}
    >
      <Logo src={logo} alt={`${name} Integration logo`} />
      <Titles>
        <Typography className='name'>{capitalise(name)}</Typography>
        {!installed && <Typography className='subTitle'>{subTitle}</Typography>}
        {installed && (
          <Typography className='subTitle'>
            <Icon icon='DoneIcon' className='success'></Icon>Installed
          </Typography>
        )}
      </Titles>
      <Status label={status} />
    </Button>
  )
}

export default IntegrationButton
