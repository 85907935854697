import { gql } from '@apollo/client'

// This should be replaced by the graplql-codegen types,
// once the grpahql api is available
// TODO: Remove when graphql schema types are available
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export enum ConversationStatus {
  UNREAD = 'unread',
  READ = 'read',
  RESOLVED = 'resolved',
}

export const GET_CONVERSATIONS = gql`
  query getConversations($status: ConversationStatus!, $size: Int!) {
    conversations(input: { status: $status, pagination: { size: $size } }) {
      items {
        id
        status
        user {
          firstName
          lastName
          avatarUrl
        }
        audit {
          created {
            date
          }
        }
      }
      pageInfo {
        next
      }
    }
  }
`

export const ON_CREATE_CONVERSATION_SUBSCRIPTION = gql`
  subscription onCreateConversation($clientId: ID) {
    onCreateConversation(clientId: $clientId) {
      id
      clientId
      status
      user {
        firstName
        lastName
        avatarUrl
      }
      audit {
        created {
          date
        }
      }
    }
  }
`
