import styled from 'styled-components'
import List from '@mui/material/List'
import MuiListItem from '@mui/material/ListItem'

export const Container = styled.div`
  background-color: #e5e5e5;
  border-right: 1px #e7e7e9 solid;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;

  header {
    background-color: #ffffff;
    color: #1d1e2e;
    box-shadow: none;
  }
`

export const MessageList = styled(List)`
  width: 100%;
  overflow: auto;
  padding: 1rem 2rem;
`

export const ListItem = styled(MuiListItem)`
  align-items: flex-start;

  .messenger-icon {
    height: 2rem;
    width: 2rem;
  }
`
