import Link from '@mui/material/Link'

// placeholder navigation component
export const Nav = () => {
  return (
    <div>
      <Link href='/inbox' underline='hover'>
        Inbox
      </Link>
    </div>
  )
}
