import styled from 'styled-components'

export const StepsList = styled.ol`
  list-style: none;
  counter-reset: item;
  padding: 0;
  margin: 0;
`

export const ControlContainer = styled.div`
  display: flex;
  align-items: center;
`

export const AuthStatus = styled.div`
  flex-grow: initial;
  display: flex;
  align-self: center;
  align-items: baseline;

  .MuiChip-root {
    margin-left: 0.5rem;
    background-color: #e7daf9;
    color: #7f32dc;
    font-weight: bold;
  }
`
