import Divider from '@mui/material/Divider'
import { Header } from 'components/Header'

import { IntegrationSteps } from 'components/Onboarding/IntegrationSteps'
import { IntegrationContextProvider } from 'context/IntegrationContext'

import { Container, StyledText } from './MessengerIntegration.styles'

const MessengerIntegration = () => {
  return (
    <>
      <Header title='Facebook Messenger' />
      <Container>
        <StyledText>
          Messenger is a messaging application that connects people with friends
          and businesses across platforms and devices.
        </StyledText>
        <StyledText>
          This integration allows your customers to chat with your team from
          Facebook and the Messenger app.
        </StyledText>
        <Divider />
        <IntegrationContextProvider>
          <IntegrationSteps></IntegrationSteps>
        </IntegrationContextProvider>
      </Container>
    </>
  )
}

export default MessengerIntegration
