// hook to predefined channels

import { useEffect, useState } from 'react'

import logo from 'assets/Images/messenger-logo.png'
import { IntegrationStatusTypes } from 'components/Onboarding/IntegrationButton'
import { IntegrationName } from 'components/Onboarding/IntegrationButton/IntegrationButton.types'

export interface Channel {
  id: string
  type: IntegrationName
  logo?: string
  subTitle?: string
  status: IntegrationStatusTypes
}

const useFetchChannels = () => {
  const [channels, setChannels] = useState<Channel[] | []>([])

  useEffect(() => {
    // load channels data
    setChannels([
      {
        id: 'messenger_channel',
        type: IntegrationName.MESSENGER,
        logo: logo,
        subTitle: 'Install in 60 secs',
        status: IntegrationStatusTypes.FREE,
      },
    ])
  }, [])

  return [channels]
}

export default useFetchChannels
