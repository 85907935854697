import { useContext } from 'react'
import { QRCodeSVG } from 'qrcode.react'

import AccordionItem, {
  AccordionItemProps,
} from 'components/AccordionItem/AccordionItem'
import { IntegrationContext } from 'context/IntegrationContext'
import { Container, Content } from './InboxStep.styles'

const InboxStep = () => {
  const { facebookIntegrationState: integrations } =
    useContext(IntegrationContext)

  const accordionProps: AccordionItemProps = {
    id: 'Step',
    icon: 'CustomInboxIcon',
    title: 'Get to know your Inbox',
    subTitle:
      'Send a message to your Inbox. Your message will be waiting in the queue as a ticket.',
  }
  return (
    <AccordionItem {...accordionProps}>
      {integrations.current.length ? (
        <Container>
          <QRCodeSVG
            data-testid='qrCodeImage'
            value={integrations.current[0].url}
            size={85}
          />
          <Content>
            <p>Test with Facebook Messenger</p>
            <ol>
              <li>Scan the QR code with your phone</li>
              <li>Send a message over Facebook to your page</li>
              <li>Close the dialog and your ticket will be in the queue</li>
            </ol>
          </Content>
        </Container>
      ) : null}
    </AccordionItem>
  )
}

export default InboxStep
