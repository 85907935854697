import { Icon } from 'components/Icon'

import { IconTitleProps } from './IconTitle.types'
import { StyledIconTitle, StyledIcon } from './IconTitle.styles'

const IconTitle = ({ icon, title, ...props }: IconTitleProps) => (
  <StyledIconTitle {...props}>
    <StyledIcon className='iconContainer'>
      <Icon icon={icon} />
    </StyledIcon>
    <div>{title}</div>
  </StyledIconTitle>
)

export default IconTitle
