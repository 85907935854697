import { ReactNode, createContext } from 'react'

import useOnboarding, { CompletedSteps, StepContext } from 'hooks/useOnboarding'

type ContextType = {
  state?: CompletedSteps
  children: ReactNode
}

export const OnboardingContext = createContext<StepContext>({
  completedSteps: { channel: false, inbox: false },
  setCompletedSteps: () => null,
})

export const OnboardingContextProvider = ({
  state = { channel: false, inbox: false },
  children,
}: ContextType) => {
  const { completedSteps, setCompletedSteps } = useOnboarding(state)
  return (
    <OnboardingContext.Provider value={{ completedSteps, setCompletedSteps }}>
      {children}
    </OnboardingContext.Provider>
  )
}
