import List from '@mui/material/List'
import styled from 'styled-components'

export const Container = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const ErrorContainer = styled.div`
  padding: 1rem;
`

export const StyledList = styled(List)`
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
`
